import React, { ChangeEvent } from "react";
import styles from "./Summary.module.scss"
import { IMeeting } from "../Models";
import { Box, Button, CircularProgress, Divider, Stack, Typography } from "@mui/material";
import { ArrowBack } from '@mui/icons-material';
import { getSessionName } from "../App";

interface ISummaryProps{
  meeting: IMeeting;
  isLoading: boolean;
  summary: string;
  userType: 'admin'|'user'|null;
  fileChange: (e: ChangeEvent)=>void
  delete: ()=>Promise<void>
  setSelectedMeeting: (meeting: IMeeting|undefined)=>void
}

const Summary = (props: ISummaryProps) => {
  return(
    props.meeting?
      <>
        <Box sx={{display: {xs:'block', sm:'none'}}}>
          <Button size="small" onClick={()=>props.setSelectedMeeting(undefined)}><ArrowBack/>Back</Button>
        </Box>
          <Typography variant="h4" component={"h2"}>{props.meeting?.title}</Typography>
          <Divider/>
          
          <Typography variant="overline">{props.meeting?.date.toLocaleString("en-US", {weekday:"long", month:"long", day:"numeric"})}</Typography>          
          <Stack direction={"row"} rowGap={2} columnGap={2}>
            <Stack>
              {/* <Typography><Box fontWeight={"700"}>Location</Box></Typography> */}
              <Typography><Box fontWeight={"700"}>Session</Box></Typography>
            </Stack>
            <Stack>
              {/* <Typography>{props.meeting?.location}</Typography> */}
              <Typography>{getSessionName(props.meeting?.session || "")}</Typography>
            </Stack>
          </Stack>
          
          <br/>
          <Typography variant="h5" component={"h3"}>Summary</Typography>
          <Box
            sx={{
              padding: '1rem',
              borderRadius: '15px',
              border: 1,
              borderColor: 'primary.main',
              marginTop: '1rem'
            }}
          >
            <div dangerouslySetInnerHTML={{__html:props.summary || "🤖 An AI generated summary will appear after the meeting."}}/>
          </Box>
          <div>
            <br/>
            <Stack spacing={2} direction={'row'} sx={{display: props.userType==="admin"?'block':'none'}}>
              <Button variant='contained' component="label">
                Upload transcript
                <input
                  type="file"
                  accept=".txt,.vtt"
                  id="file-transcript"
                  onChange={props.fileChange}
                  style={{ display: "none" }}
                ></input>
              </Button>
              <Button onClick={props.delete}>Delete meeting</Button>
            </Stack>
          </div>
          <div hidden={!props.isLoading}>
            <br/>
            <Box sx={{display:"flex", flexDirection:"row"}}>
              <CircularProgress></CircularProgress>
              <Typography>&nbsp;&nbsp;&nbsp;&nbsp;Loading...</Typography>
            </Box>
          </div>
        </>
        : 
        <div>
          <Typography>Select a meeting to see its summary.</Typography>
        </div>
      
  )
}

export default Summary;
import React, {useEffect, useState, ChangeEvent} from 'react';
import logo from './logo.svg';
import './App.css';
import {List, ListItem, ListItemText, Container, Typography, Divider, Button, Modal, Box, TextField, Stack, CircularProgress, Alert, AppBar, IconButton, Toolbar, Snackbar, Select, MenuItem, Autocomplete } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import MenuIcon from '@mui/icons-material/Menu';
import { get } from 'http';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { IMeeting } from './Models';
import Summary from './components/Summary';
import dayjs from 'dayjs';


//const API_BASE = "http://127.0.0.1:5000";
const API_BASE = "https://wa-np-tl-ai-summary.azurewebsites.net"
const API_KEY = "Z!37v!23BtB5%L7*E$uBM*L4#L194A";


{/* <MenuItem value={"future"}>The Future</MenuItem>
                  <MenuItem value={"gap"}>The Gap</MenuItem>
                  <MenuItem value={"plan"}>The Plan</MenuItem> */}

export function getSessionName(text: string){
  if(text==="future"){
    return "The Future"
  }
  else if (text==="gap"){
    return "The Gap"
  }
  else if (text==="plan"){
    return "The Plan"
  }
  else{
    return text
  }
}

function replaceWithBr(input:string) {
  return input.replace(/\n/g, "<br />")
}

function App() {

  const [meetings, setMeetings] = useState<IMeeting[]>([])
  const [selectedMeeting, setSelectedMeeting] = useState<IMeeting>();
  const [selectedSummary, setSelectedSummary] = useState("");
  const [isOpenMeetingModal, setIsOpenMeetingModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [userType, setUserType] = useState<'admin'|'user'|null>();
  const [password, setPassword] = useState("");
  // add modal inputs
  const [meetingTitle, setMeetingTitle] = useState("");
  const [meetingDate, setMeetingDate] = useState(dayjs(new Date()));
  const [meetingLocation, setMeetingLocation] = useState("");
  const [meetingParticipants, setMeetingParticipants] = useState("");
  const [meetingSession, setMeetingSession] = useState("");


  const LS_PASSWORD_KEY = "password"

  const getUserType = async (lsPassword?: string) =>{
    if(lsPassword || password){
      const formData = new FormData();
      formData.append("password", lsPassword || password);
      try{
        setIsLoading(true);
        const response = await fetch(`${API_BASE}/getusertype`, {
          method: 'POST',
          headers:{
            "x-api-key": API_KEY
          },
          body: formData
        })
        setIsLoading(false);
        const data = await response.json();
        const data_usertype = data.user_type;
        setUserType(data_usertype);
        if(data_usertype!==null){
          // TODO: add to local storage
          localStorage.setItem(LS_PASSWORD_KEY, lsPassword || password);
          getMeetings();
        }
        else{
          showError('password is incorrect');
        }
      }catch(error){
        console.log(error);
        setIsLoading(false);
        showError("Network error: API could be offline.");
      }
    }
  }

  const logout = () =>{
    localStorage.clear();
    setPassword("");
    setUserType(null);
  }

  const getMeetings = async () =>{
    try{
      setIsLoading(true);
      const response = await fetch(`${API_BASE}/api/get-all`, {
        method:'GET',
        headers:{
          "x-api-key": API_KEY
        }
      })
      setIsLoading(false);
      const data = await response.json();
      if(data.success===true){
        if(data.records && Array.isArray(data.records)){
          const meetingsTemp:IMeeting[] = data.records.map((arrayItem:any)=>{
            return{
              title: arrayItem[0],
              date: new Date(arrayItem[1]),
              uuid: arrayItem[2],
              participants: arrayItem[3],
              location: arrayItem[4],
              session: arrayItem[5]
            }
          })
          setMeetings(meetingsTemp);
        }
      }
    }catch(error){
      setIsLoading(false);
      console.log(error);
      showError("Network error: API could be offline.");
    }
  }

  const showError = (error: string) =>{
    setErrorMessage(error)
    setTimeout(() => {
      setErrorMessage("");
    }, 7000);
  }

  const showSuccess = (message: string)=>{
    setSuccessMessage(message);
    setTimeout(() => {
      setSuccessMessage("");
    }, 5000);
  }

  const getSummary = async (uuid: string)=>{
    try{
      setIsLoading(true);
      const response = await fetch(`${API_BASE}/api/summary/${uuid}`, {
        method: 'GET',
        headers:{
          "x-api-key": API_KEY
        }
      })
      setIsLoading(false);
      const data = await response.json();
      if(data.success===true){
        setSelectedSummary(replaceWithBr(data.summary));
      }
      else{
        //showError(data.error || "error");
        setSelectedSummary("");
      }
    }
    catch(error){
      setIsLoading(false);
      showError("Network error: API could be offline.");
    }
  }

  const openAddModal = ()=>{
    setIsOpenMeetingModal(true);
    clearAddForm();
  }

  const clearAddForm = ()=>{
    setMeetingTitle("");
    setMeetingDate(dayjs(new Date()));
    setMeetingLocation("");
    setMeetingParticipants("");    
  }

  const addMeeting = async ()=>{
    //TODO: validate form
    const formData = new FormData();
    formData.append("title", meetingTitle);
    formData.append("date", meetingDate.toString());
    if(meetingLocation){
      formData.append("location", meetingLocation)
    }
    if(meetingParticipants){
      formData.append("participants", meetingParticipants)
    }
    if(meetingSession){
      formData.append("session", meetingSession);
    }
    try{
      setIsLoading(true);
      const response = await fetch(`${API_BASE}/api/add`, {
        method: 'POST',
        headers:{
          "x-api-key": API_KEY
        },
        body: formData
      })
      const data = await response.json();
      setIsLoading(false);
      if(data.success == true){
        //TODO: add a toast showing the operation succeded.
        getMeetings();
        clearAddForm();
        setIsOpenMeetingModal(false);
        showSuccess("The meeting was created.")
      }
      else{
        showError(data.error || "error");
      }
    }
    catch(error){
      setIsLoading(false);
      showError("Network error: API could be offline.");
    }    
  }

  const handleFileChange = (e: ChangeEvent) => {
    e.preventDefault();
    if (e.target) {
      const input = e.target as HTMLInputElement;
      if (input && "files" in input) {
        const file0 = input.files ? input.files[0] : null;
        if(file0){
          uploadSummary(file0);
        }        
      }
    }
  };

  const uploadSummary = async (file:File) =>{
    if(selectedMeeting){
      const formData = new FormData();
      formData.append("record", file);
      formData.append("id", selectedMeeting.uuid);
      try{
        setIsLoading(true);
        const response = await fetch(`${API_BASE}/api/upload`, {
          method: 'POST',
          headers:{
            "x-api-key": API_KEY
          },
          body: formData
        })
        setIsLoading(false);
        const data = await response.json();
        if(data.success==true){
          getSummary(selectedMeeting.uuid);
          showSuccess("The summary was uploaded.")
        }
        else{
          showError(data.error || "error");
        }
      }
      catch(error){
        setIsLoading(false);
        showError("Network error: API could be offline.");
      }
    }
  }

  const deleteMeeting = async () =>{
    if(selectedMeeting){
      const formData = new FormData();
      formData.append("id", selectedMeeting.uuid);
      try{
        setIsLoading(true);
        const response = await fetch(`${API_BASE}/api/delete`, {
          method: 'POST',
          headers:{
            "x-api-key": API_KEY
          },
          body: formData
        });
        setIsLoading(false);
        const data = await response.json();
        if(data.success==true){
          getMeetings();
          setSelectedMeeting(undefined);
          showSuccess("The meeting was deleted.")
        }
        else{
          showError(data.error || "error");
        }
      }
      catch(error){
        setIsLoading(false);
        showError("Network error: API could be offline.");
      }
    }
  }

  const getItemsBySession = (meetings: IMeeting[], session?: string)=>{
    return meetings.filter((item)=>{
      if(session){
        return item.session === session && item.session!==""
      }
      else{
        if(item.session === "" || item.session === null ){
          return true;
        }
        else{
          if(item.session)
            return ["future", "gap", "plan"].indexOf(item.session) === -1;
          else
            return false;
        }
      }
    }).map((meeting: IMeeting, index: number)=>{
      return(
        <>                
          <ListItem key={index} onClick={()=>{setSelectedMeeting(meeting); getSummary(meeting.uuid)}}>
            <ListItemText>
              <Typography variant="overline">{meeting.date.toLocaleDateString()}</Typography>
              <Typography variant="h6">{meeting.title}</Typography>
              {/* <Typography>{getSessionName(meeting.session || "")}</Typography> */}
            </ListItemText>
          </ListItem>
          <Divider variant="inset" component="li" />
        </>
      )
    })
  }

  useEffect(()=>{
    //TODO: check if local storage has password and user type
    const lsPassword = localStorage.getItem(LS_PASSWORD_KEY);
    if(lsPassword!==null){
      getUserType(lsPassword);
    }
  },[])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {userType==="admin"|| userType==="user" ?
        <>
        <Box sx={{ flexGrow: 1}}>
          <AppBar position='static'>
            
              <Container maxWidth="md">
                <Toolbar>
                {/* <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                >
                  <MenuIcon />
                </IconButton> */}
                <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
                  🇹🇹 Education Reset AI Summaries
                </Typography>
                <Button color="inherit" onClick={()=>logout()}>Logout</Button>
                </Toolbar>
              </Container>
            
          </AppBar>
        </Box>
        <Container maxWidth="md" sx={{minHeight:'85vh'}}>        
          <div hidden={errorMessage==""}>
            <Alert severity='error'>{errorMessage}</Alert>
          </div>
          <Snackbar open={successMessage!==""} anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}>
            <Alert severity='success'>
              {successMessage}
            </Alert>
          </Snackbar>
          <Grid container spacing={2}>                        
              <Grid xs={12} sm={4} sx={{padding:'1em', display:{xs: selectedMeeting?'none':'block', sm:'block'}}}>
                <Typography variant='h6'>The Future</Typography>      
                <List>
                { getItemsBySession(meetings, "future")}
                </List>
                <Typography variant='h6'>The Gap</Typography>      
                <List>
                { getItemsBySession(meetings, "gap")}
                </List>
                <Typography variant='h6'>The Plan</Typography>      
                <List>
                { getItemsBySession(meetings, "plan")}
                </List>
                <Typography variant='h6'>Others</Typography>      
                <List>
                { getItemsBySession(meetings)}
                </List>
                <Button onClick={()=>openAddModal()} sx={{display: userType==="admin"?'block':'none'}}>Add meeting</Button>
              </Grid>
            
            <Grid xs={12} sm={8} sx={{padding:'1em', display: { xs: selectedMeeting?'block':'none', sm: 'block' }}}>
              {selectedMeeting && userType?
                <Summary 
                  meeting={selectedMeeting}
                  isLoading={isLoading}
                  summary={selectedSummary}
                  fileChange={handleFileChange}
                  userType={userType}
                  delete={deleteMeeting}
                  setSelectedMeeting={setSelectedMeeting}
                />
                :<></>
                }
            </Grid>
          </Grid>
          <Modal
            open={isOpenMeetingModal}
            onClose={()=>setIsOpenMeetingModal(false)}
          >
            <Box sx={{
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              border: 1,
              borderColor: 'primary.dark',
              borderRadius: '15px',
              boxShadow: 24,
              p: 4,
            }}>
              <h2>Add a new meeting</h2>
              <Stack spacing={2}>

                <Autocomplete
                  freeSolo
                  autoSelect
                  options={['Infrastructure','Governance', 'People and Culture', 'Platforms and Content for Learning', 'Platforms and Content for Management']}
                  renderInput={(params: any)=><TextField {...params} label="title"/>}
                  value={meetingTitle}
                  onChange={(event, value)=>setMeetingTitle(value || "")}
                />
                {/* <Select
                  required
                  label="title"
                  value={meetingTitle}
                  onChange={(event)=>setMeetingTitle(event.target.value)}
                >
                  <MenuItem value={"Infrastructure"}>Infrastructure</MenuItem>
                  <MenuItem value={"Governance"}>Governance and Institutions</MenuItem>
                  <MenuItem value={"People and Culture"}>People and Culture</MenuItem>
                  <MenuItem value={"Platforms and Content for Learning"}>Platforms and Content for Learning</MenuItem>
                  <MenuItem value={"Platforms and Content for Management"}>Platforms and Content for Management</MenuItem>
                </Select> */}
                <DateTimePicker label="Date" 
                  value={meetingDate}
                  onChange={(value)=>setMeetingDate(value || dayjs(new Date()))}
                />

                <Autocomplete
                  freeSolo
                  autoSelect
                  options={['future','gap', 'plan', ]}
                  renderInput={(params: any)=><TextField {...params} label="session"/>}
                  value={meetingSession}
                  onChange={(event, value)=>setMeetingSession(value || "")}
                />
                
                {/* <Select
                  required
                  label="session"
                  value={meetingSession}
                  onChange={(event)=>setMeetingSession(event.target.value)}
                >
                  <MenuItem value={"future"}>The Future</MenuItem>
                  <MenuItem value={"gap"}>The Gap</MenuItem>
                  <MenuItem value={"plan"}>The Plan</MenuItem>
                </Select> */}
                {/* <TextField
                  label="location"
                  value={meetingLocation}
                  onChange={(event)=>setMeetingLocation(event.target.value)}
                />
                <TextField
                  label="participants"
                  value={meetingParticipants}
                  onChange={(event)=>setMeetingParticipants(event.target.value)}
                  multiline
                  rows={4}
                /> */}
                <Button variant="contained" onClick={()=>addMeeting()}>Add</Button>
              </Stack>
            </Box>
          </Modal>
          
        </Container>
        <Container maxWidth="md">
          <Box sx={{padding:'0.5rem 0 1rem 0'}}>
            <Typography>Built with ❤️ by IDB TechLab</Typography>
          </Box>
        </Container>
        </>
        :
        <Container maxWidth="sm">          
          <Stack justifyContent={"center"} direction={"column"} sx={{height:'100vh'}} spacing={2}>
            <div hidden={errorMessage==""}>
              <Alert severity='error'>{errorMessage}</Alert>
            </div>
            <div hidden={!isLoading}>
              <br/>
              <Box sx={{display:"flex", flexDirection:"row"}}>
                <CircularProgress></CircularProgress>
                <Typography>&nbsp;&nbsp;&nbsp;&nbsp;Loading...</Typography>
              </Box>
            </div>
            <Typography variant="h4">Education Reset AI Summaries</Typography>
            <TextField
              type='password'
              label="password"
              value={password}
              onChange={(event)=>setPassword(event.target.value)}
              onKeyDown={(event)=>{
                if(event.key === 'Enter'){
                  getUserType();
                }
              }}
            />
            <Button variant="contained" onClick={()=>getUserType()}>Login</Button>
          </Stack>
        </Container>
      }      
    </LocalizationProvider>
  );
}

export default App;
